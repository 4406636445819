import { AlertLevel, Severity } from './enums';

export type GenericFunctionType = () => void;
export type GenericAsyncFunctionType = () => Promise<void>;

interface OrgTypes {
  PANO: string;
  UTILITY: string;
  OWNER: string;
  INSURANCE: string;
  GOVERNMENT: string;
}

export const ORG_TYPES: OrgTypes = {
  PANO: 'pano',
  UTILITY: 'utility',
  OWNER: 'owner',
  INSURANCE: 'insurance',
  GOVERNMENT: 'government',
};

export interface CanvasPos {
  x: number;
  y: number;
  z: number;
  from?: string;
  bearing?: number;
  centerPan?: number;
}

export interface PhoneCode {
  code: string;
  mask: string;
  name: string;
  format: (_v: string) => string;
}

export type CountryPhone = Record<string, PhoneCode>;

export interface Option {
  label: string;
  value: string;
  icon?: string;
  href?: string;
  labelTip?: string;
}

export type LonLatArray = [number, number];

export type CameraType = {
  make: string;
  model: string;
  tiltMax: number;
  tiltMin: number;
  zoomMax: number;
};

export type Place = {
  city?: string;
  country?: string;
  county?: string;
  state?: string;
};

export type UsState = {
  name: string;
  abbreviation: string;
};

export type StationCamera = {
  dois: number;
  fov: LonLatArray;
  markability: number;
  offPan: number;
  offPhy: number;
  ordinal: number;
  pan: number;
  period?: number;
  /**
   * The state of this camera
   * - pano - In panorama image capture mode.
   * - manual - In manual control mode (for OZ)
   * - stopped  - Deliberately stopped by a user for maintenance or whatnot. Camera cannot be programmatically changed to pano or manual from this state.
   * - error - Camera error. Camera cannot be programmatically changed to pano or manual from this state.
   */
  state: 'pano' | 'manual' | 'stopped' | 'error';
  tilt: number;
  /**
   * The distance a camera can see in KM
   */
  visibility: number;
  zoom: number;
  correlationId?: string;
};

export interface Mark {
  canvas: {
    x: number;
    y: number;
    z: number;
  };
  image: {
    x: number;
    y: number;
  };
  time?: number;
  startTime?: number;
}

export interface NotificationMessage {
  level: AlertLevel;
  message: string;
}

// Ensure exact type when creating a new object.
// @doc https://stackoverflow.com/questions/75721666/enforce-type-checking-for-object-created-by-spreading?noredirect=1#comment133580331_75721666
// @doc https://www.typescriptlang.org/play?#code/C4TwDgpgBAKuEEEoF4oG8BQUoEsAmAXFAHYCuAtgEYQBOA3BgL4YaiSzwBCK6WJAhuQhEAzsBo5iAcwbMMAYwD2xMVH5E4kJKjS5CUAMxRGDJSuBRKGrj13FBwqAHJyIJ8YYLlqiAA9+8sAANiA8ADwwUH7AEMR4IlCKlABWEIEANAB8ABQAlCiZUGEAqlG+MXEJkQBk6FAA2gAKuMRQAKK+8kGkeBBhANYQIIoAZlDF6VCDw2MwmQC6RMQQAG60xjmkRMW5GgVQpJ5mPv6BIZqIPH4BwSAR8Ag5uUfeFvJXp7cXCNmY2AB0gP46T4gP+lBBjGeUAA9DCojQaIoaABCDiQBKjKBgJGQGigZz2ITufg0aCSJTkMD8YA4ShBCD-FjHCx4D43c4PX6goFMfJwxL9fggIA
export const exactType =
  <T extends object>() =>
  <
    U extends T & {
      // eslint-disable-next-line no-unused-vars
      [P in Exclude<keyof U, keyof T>]: never;
    },
  >(
    u: U,
  ): T =>
    u;

export type WindowSize = {
  width: number;
  height: number;
};

export type GlobalAlertParams = {
  message: string;
  severity?: Severity;
};

export type UnitSystemShorthand = 'si' | 'iu';
