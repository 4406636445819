import dayjs from 'dayjs';
import { Dictionary } from 'lodash';
import _compact from 'lodash/compact';
import _every from 'lodash/every';
import _flatten from 'lodash/flatten';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _groupBy from 'lodash/groupBy';
import _head from 'lodash/head';
import _mapValues from 'lodash/mapValues';
import _round from 'lodash/round';
import _trim from 'lodash/trim';
import _uniqBy from 'lodash/uniqBy';
import { LayerProps } from 'react-map-gl';
import {
  GDACoordinates,
  Incident,
  INCIDENT_LABEL,
  IncidentCamera,
  IncidentColors,
  IncidentLabel,
  LonLat,
  LonLatArray,
  MapMode,
  MapSourceData,
  Station,
  StationsMap,
} from 'types';
import { getIncidentLabel, hasLngLat, hasNoLngLat, isIncidentActive } from 'utils/incident';

import {
  CAMERA_IN_VIEW_CONE_ANGLE,
  CAMERA_UNCERTAINTY_ANGLE,
  computeDestinationPointLonLat,
  getCameraCircleLatLng,
  getCameraUncertaintyAngleByMapZoom,
  getPolygonPointsLatLng,
  getRectPointsLatLng,
} from './geoLocation';

const MAX_LNGLAT_SCALE: number = 7;

const lightMode: string = 'mapbox://styles/mapbox/light-v9';
const darkMode: string = 'mapbox://styles/mapbox/dark-v9';
const outdoorsMode: string = 'mapbox://styles/mapbox/outdoors-v11';
const satelliteStreetsMode: string = 'mapbox://styles/mapbox/satellite-streets-v11';

export const mapModes: Record<MapMode, string> = {
  light: lightMode,
  dark: darkMode,
  map: outdoorsMode,
  satellite: satelliteStreetsMode,
};

export const mapList: { name: MapMode; img: string }[] = [
  { name: 'map', img: 'outdoors-v11' },
  { name: 'satellite', img: 'satellite-streets-v11' },
];

export const incidentResolutionTitle: Record<IncidentLabel, string> = {
  possible: 'Smoke Investigation',
  confirmed: 'Alerted Incident',
  prescribed: 'Controlled Burn',
  dismissed: 'Dismissed Alert',
  closed: 'Closed Incident',
};

export const getIncidentLabelTime = (label: IncidentLabel, readableTime = ''): string =>
  ({
    [INCIDENT_LABEL.POSSIBLE]: `Detected ${readableTime}`,
    [INCIDENT_LABEL.PRESCRIBED]: `Active for ${readableTime}`,
    [INCIDENT_LABEL.CONFIRMED]: `Active for ${readableTime}`,
    [INCIDENT_LABEL.DISMISSED]: `Dismissed ${readableTime}`,
    [INCIDENT_LABEL.CLOSED]: `Closed for ${readableTime}`,
  }[label] || readableTime);

export const incidentSourceDescPopup: Record<string, string> = {
  dispatch: '911',
  cv: 'PanoCam',
  burn: 'Active',
  satellite: 'Satellite',
  closed: 'Closed',
  user: 'User',
};

//function to calculate the time period incident start time and now
export function getIncidentHappenedDuration(startTime: number): string {
  let durationSymbol: string = 'm';
  const endTime: dayjs.Dayjs = dayjs();
  const seconds: number = endTime.diff(startTime * 1000, 'seconds');
  let duration: number = seconds / 60;
  if (duration < 1) duration = 1;
  if (duration > 60) {
    duration = duration / 60;
    durationSymbol = 'h';
    if (duration > 24) {
      duration = duration / 24;
      durationSymbol = 'd';
    }
  }
  return Math.floor(duration) + durationSymbol;
}

//fill color for camera area circle
export const stationDataLayer: LayerProps = {
  id: 'data',
  type: 'fill',
  paint: {
    'fill-color': {
      property: 'opacity',
      stops: [
        [0, 'rgba(53, 131, 188, 0.15)'],
        [1, 'rgba(53, 131, 188, 0.15)'],
      ],
    },
    'fill-opacity': 1,
  },
};

/**
 * Get camera circle polygon coordinates
 * These are used to create the map layer
 */
export function getCameraArea(camera: Station): MapSourceData {
  const points: number[][] = getCameraCircleLatLng(camera);
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: { opacity: 0 },
        geometry: {
          type: 'MultiPolygon',
          coordinates: [[points]],
        },
      },
    ],
  };
}

const INCIDENT_COLORS: IncidentColors = {
  POSSIBLE: '6, 118, 188',
  PRESCRIBED: '108, 115, 132',
  CONFIRMED: '226, 3, 9',
  CLOSED: '108, 115, 132',
  DISMISSED: '108, 115, 132',
};

//fill color for camera area polygon to incident
export const getIncidentDataLayer = (
  incident: Incident,
  definedLabel = '',
  definedId = '',
  cameraOnly = false,
): LayerProps => {
  const label: string = definedLabel || getIncidentLabel(incident);
  const rgb: string = cameraOnly
    ? '59, 169, 244'
    : INCIDENT_COLORS[label.toUpperCase() as keyof typeof INCIDENT_COLORS] || '226, 3, 9';
  const id: string = definedId || (definedLabel ? `${definedLabel.toLowerCase()}-data` : 'data');
  return {
    id,
    type: 'fill',
    paint: {
      'fill-color': {
        property: 'opacity',
        stops: [
          [0.1, `rgba(${rgb}, 0.1)`],
          [0.5, `rgba(${rgb}, 0.5)`],
          [1, `rgba(${rgb}, 1)`],
        ],
      },
      'fill-outline-color': 'transparent',
      'fill-opacity': 1,
    },
  };
};

export const getAllIncidentsDataLayer = (): Record<string, LayerProps> => {
  const labeledLayers: Record<string, LayerProps> = {};
  Object.keys(INCIDENT_COLORS).forEach((definedLabel) => {
    labeledLayers[definedLabel.toLowerCase()] = getIncidentDataLayer(null, definedLabel);
  });

  return labeledLayers;
};

export const labeledIncidentLayers: Record<string, LayerProps> = getAllIncidentsDataLayer();

const basePolygon = (points: LonLatArray[], opacity = 1): GeoJSON.Feature => ({
  type: 'Feature',
  properties: { opacity },
  geometry: {
    type: 'MultiPolygon',
    coordinates: [[points]],
  },
});

export function getStationViewPolygon(
  incidentCamera: IncidentCamera,
  station: Station,
  incident: Incident,
  bearing: number = null,
  angle: number = CAMERA_IN_VIEW_CONE_ANGLE,
): MapSourceData {
  const polygonPoints: LonLatArray[] = getPolygonPointsLatLng({
    incidentCamera,
    station,
    coneAngle: angle,
    canvasBearing: bearing,
  });
  const cone: GeoJSON.Feature = basePolygon(polygonPoints, 0.3);
  return {
    type: 'FeatureCollection',
    features: [cone],
  };
}

export function getIncidentCameraCones(incident: Incident, mapZoom: number, stationsMap: StationsMap): MapSourceData {
  const features: GeoJSON.Feature[][] = incident.cameras
    .filter((c) => !!stationsMap[c?.id])
    .map((incidentCamera) => {
      const station: Station = stationsMap[incidentCamera?.id];
      const areaPolygonPoints: LonLatArray[] = getPolygonPointsLatLng({ incidentCamera, station });
      const shapes: [GeoJSON.Feature] = [basePolygon(areaPolygonPoints, 0.3)];

      if (hasLngLat(station)) {
        const rectPoints: LonLatArray[] = getRectPointsLatLng(incident, station, incidentCamera, mapZoom);
        const rect: GeoJSON.Feature = basePolygon(rectPoints, 0.3);
        shapes.push(rect);

        const uncertaintyPolygonPoints: LonLatArray[] = getPolygonPointsLatLng({
          incidentCamera,
          station,
          coneAngle: CAMERA_UNCERTAINTY_ANGLE,
        });
        const uncertaintyCone: GeoJSON.Feature = basePolygon(uncertaintyPolygonPoints, 0.5);
        shapes.push(uncertaintyCone);
      }

      return shapes;
    });

  return {
    type: 'FeatureCollection',
    features: _flatten(features),
  };
}

/**
 * @param incidents
 * @param currentIncident
 * @param stationsMap
 * @param mapZoom
 * @param enforcedIncidents: always show current incident even if it's inactive
 */
export function getCamerasConesForIncidents(
  incidents: Incident[],
  currentIncident: Incident,
  stationsMap: StationsMap,
  mapZoom: number,
  enforcedIncidents: Incident[] = [],
): Record<string, MapSourceData> {
  const groupedData: Record<string, MapSourceData> = {};
  const allIncidents: Incident[] = _uniqBy(incidents.filter(isIncidentActive).concat(enforcedIncidents), 'id');
  const incidentsGroupedByLabel: Dictionary<Incident[]> = _groupBy(allIncidents, getIncidentLabel);
  _forEach(incidentsGroupedByLabel, (labeledIncidents, label) => {
    const features: GeoJSON.Feature[][][] = labeledIncidents.map((incident) => {
      // If no currentIncident, highlight all; otherwise, highlight the currentIncident
      const highlight: boolean = !currentIncident || incident.id === currentIncident?.id;
      return incident.cameras
        .filter((c) => c.mark)
        .filter((c) => !!stationsMap[c?.id])
        .map((incidentCamera) => {
          const station: Station = stationsMap[incidentCamera?.id];
          const rectPoints: LonLatArray[] = getRectPointsLatLng(incident, station, incidentCamera, mapZoom);
          const rect: GeoJSON.Feature = basePolygon(rectPoints, highlight ? 0.3 : 0.25);

          if (!highlight) {
            return [rect];
          }

          const uncertaintyPolygonPoints: LonLatArray[] = getPolygonPointsLatLng({
            incidentCamera,
            station,
            coneAngle: getCameraUncertaintyAngleByMapZoom(mapZoom),
          });
          const uncertaintyCone: GeoJSON.Feature = basePolygon(uncertaintyPolygonPoints, highlight ? 1 : 0.5);

          return [rect, uncertaintyCone];
        });
    });

    groupedData[label.toLowerCase()] = {
      type: 'FeatureCollection',
      features: _flatten(_flatten(features)),
    };
  });

  return groupedData;
}

export function getIncidentUncertainFireLonLat(incident: Incident, stations: Station[]): LonLat {
  const incidentCamera: IncidentCamera = _head(incident?.cameras);
  const station: Station = stations.find((i: Station) => i?.id === incidentCamera?.id);
  if (!incidentCamera || !station?.lon || !station?.lat) {
    // console.warn('No uncertain fire', incident);
    return null;
  }
  const bearing: number = incidentCamera.bearing;
  // @todo always use the visibility from the first camera of a station.
  const visibility: number = ((_get(incidentCamera, 'cameras.0.visibility') as number) || 20) * 1000;

  const lonLat: LonLat = computeDestinationPointLonLat({ lon: station.lon, lat: station.lat }, visibility / 2, bearing);

  if (!lonLat.lon || !lonLat.lat) {
    console.warn('cannot determine the longitude/latitude of incident', incident);
    return null;
  }

  return lonLat;
}

export const stringifyGDACoordinates = (gda: GDACoordinates) => {
  return `E:\u00A0${gda.easting}, N:\u00A0${gda.northing}, Z:\u00A0${gda.zone}`;
};

export const stringifyLonLat = (lonLat: LonLat): string => {
  return `${lonLat.lat.toFixed(5)}, ${lonLat.lon.toFixed(5)}`;
};

export const lngLatForApi = (lonLat: LonLat): LonLat => {
  return _mapValues(lonLat, (v) => _round(v, MAX_LNGLAT_SCALE));
};

export const isLonLat = (str: string): boolean => {
  const lonLat: string[] = _compact(str.split(/[, ]/));
  const isLonLatRange = (n: number): boolean => n >= -180 && n <= 180;
  return lonLat.length === 2 && _every(lonLat.map(Number), isLonLatRange);
};

export const reverseLonLat = (str: string): string => _compact(str.split(/[, ]/)).map(_trim).reverse().join(', ');

// Convert distance of two points from coordinate values to millimeter (approximate)
export const getMilliMeterDistanceFromLonLat = (loc1: LonLat, loc2: LonLat, zoom: number): number => {
  const dis: number = Math.pow(loc2.lat - loc1.lat, 2) + Math.pow(loc2.lon - loc1.lon, 2);
  return Math.sqrt(dis) * Math.pow(2, zoom - 1);
};

// The diameter of incident/station icon is 10mm.
// If center point of two incidents has distance less than MAX_OVERLAPPING_IN_MM (mm), we think they overlap, then do not plot overlapping ones.
const MAX_OVERLAPPING_IN_MM: number = 5;
export const keepNonOverlappingPoints = (points: Incident[], zoom: number): Incident[][] => {
  const nonOverlapping: Incident[][] = [];
  for (let i: number = 0; i < points.length; i++) {
    const point: Incident = points[i];
    if (hasNoLngLat(point)) {
      continue;
    }

    if (nonOverlapping.length === 0) {
      nonOverlapping.push([point]);
    } else {
      let overlapped: boolean = false;
      for (let j: number = 0; j < nonOverlapping.length; j++) {
        const pointsIn: Incident[] = nonOverlapping[j];
        const dis: number = getMilliMeterDistanceFromLonLat(point as LonLat, _head(pointsIn) as LonLat, zoom);
        if (dis <= MAX_OVERLAPPING_IN_MM) {
          overlapped = true;
          pointsIn.push(point);
          break;
        }
      }

      if (!overlapped) {
        nonOverlapping.push([point]);
      }
    }
  }

  return nonOverlapping;
};
