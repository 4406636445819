import { createTheme, Shadows } from '@mui/material/styles';

import { MOBILE_QUERY } from './base';

const palette = {
  primary: {
    main: '#0676BC',
    light: '#C5DCEA',
    dark: '#044771',
  },
  secondary: {
    light: '#EA6467',
    main: '#E20309',
    dark: '#9E0206',
  },
  error: {
    light: '#EA6467',
    main: '#E20309',
    dark: '#9E0206',
  },
  errorExtra: {
    lighter: '#EFACAE',
    extraLight: '#F3DDDD',
    '30Opacity': 'rgba(226, 3, 9, 1)',
  },
  primaryExtra: {
    extraLight: '#E2EBF0',
  },
  warning: {
    main: '#FF9C27',
    dark: '#D98521',
    light: '#FFEBD4',
  },
  success: {
    main: '#00838F',
    light: '#E0F7FA',
  },
  action: {
    disabledBackground: 'rgba(82, 171, 236, 0.5)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hover: '#EEF3F6',
  },
  headers: {
    landowners: '#3D7E45',
    utilities: '#FF9C27',
    insurance: '#044771',
    government: '#6C7384',
    pic: '#00838F',
  },
  white: {
    main: '#FAFAFA',
    '10Opacity': 'rgba(255, 255, 255, .1)',
    '20Opacity': 'rgba(255, 255, 255, .2)',
    '30Opacity': 'rgba(255, 255, 255, .3)',
    '40Opacity': 'rgba(255, 255, 255, .4)',
    '80Opacity': 'rgba(255, 255, 255, .8)',
    '100Opacity': '#FFFFFF',
  },
  greys: {
    '80Opacity': 'rgba(27, 32, 38, .8)',
    dark: '#1B2026',
    subtext: '#525969',
    light: '#6C7384',
    lighter: '#B3BAC1',
    border: '#CACFD4',
    extraLight: '#E1E3E7',
    superLight: '#F0F0F1',
    lightest: '#F7F7F7',
  },
  mapColors: {
    iris: '#5A58C6',
    royalBlue: '#4B75D0',
    unitedNationsBlue: '#3C92D9',
    resedaGreen: '#6B835D',
    darkGoldenrod: '#C48C00',
    ochre: '#C4700D',
    burntOrange: '#BF5518',
    engineeringOrange: '#Ba3a23',
    redNCS: '#B71D34',
    amaranthPurple: '#B30044',
  },
};

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    fontWeightSemiBold: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    mapControl: true;
  }
}
declare module '@mui/material/styles' {
  interface Theme {
    custom?: {
      /** The width to use for a drawer menu, which can be used to calculate how much to translate
       *  other elements that should also move when the drawer opens. */
      drawerMenuWidth: string;
    };
  }

  interface TypographyVariants {
    fontWeightSemiBold: string | number;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    fontWeightSemiBold?: string | number;
  }

  interface ThemeOptions {
    custom?: {
      drawerMenuWidth: string;
    };
  }
  interface Palette {
    primaryExtra: {
      extraLight: string;
    };
    errorExtra: {
      lighter: string;
      extraLight: string;
      '30Opacity': string;
    };
    headers: {
      landowners: string;
      utilities: string;
      insurance: string;
      government: string;
      pic: string;
    };
    white: {
      main: string;
      '10Opacity': string;
      '20Opacity': string;
      '30Opacity': string;
      '40Opacity': string;
      '80Opacity': string;
      '100Opacity': string;
    };
    greys: {
      dark: string;
      subtext: string;
      light: string;
      lighter: string;
      border: string;
      extraLight: string;
      superLight: string;
      lightest: string;
      '80Opacity': string;
    };
    mapColors: {
      iris: string;
      royalBlue: string;
      unitedNationsBlue: string;
      resedaGreen: string;
      darkGoldenrod: string;
      ochre: string;
      burntOrange: string;
      engineeringOrange: string;
      redNCS: string;
      amaranthPurple: string;
    };
  }
}

export const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  typography: {
    fontFamily: ['"Source Sans Pro"', 'Roboto', 'sans-serif'].join(','),
    fontWeightSemiBold: 600,
  },
  palette,
  custom: {
    drawerMenuWidth: '280px',
  },
  components: {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white.main,
          color: '#000000',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
          '&.Mui-disabled': {
            outline: '0',
            color: palette.primary.main,
            backgroundColor: palette.action.disabledBackground,
            opacity: '.5',
          },
          '&.Mui-selected': {
            color: palette.primary.dark,
            outline: `1px solid ${palette.primary.main}`,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        rounded: {
          '&:first-of-type': {
            borderRadius: '2px',
          },
          '&:last-child': {
            borderRadius: '2px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          [`@media ${MOBILE_QUERY}`]: {
            padding: '8px',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          borderBottom: '1px solid transparent',
          '&.Mui-expanded': {
            minHeight: '32px',
            borderBottom: '1px solid #DEE0E2',
          },
        },
        content: {
          margin: '4px 0',
          '&.Mui-expanded': {
            margin: '4px 0',
          },
        },
        expandIconWrapper: {
          marginRight: '-9px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          marginLeft: 0,
          marginRight: 0,
          alignSelf: 'flex-start',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 600,
          borderRadius: 2,
          height: '36px',
          '&.Mui-disabled': {
            color: '#0676BC',
            opacity: 0.5,
          },
        },
        sizeLarge: {
          height: '48px',
        },
        sizeSmall: {
          height: '32px',
          borderRadius: 2,
          padding: '0 18px',
        },
      },
      variants: [
        {
          props: { variant: 'mapControl' },
          style: {
            backgroundColor: palette.white.main,
            color: palette.greys.dark,
            borderRadius: 4,
            lineHeight: '100%',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
            '&:hover': {
              background: palette.action.hover,
              border: `2px solid ${palette.primary.main} `,
            },
            '&:focus': {
              border: `2px solid ${palette.primary.main} `,
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 520,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderWidth: 1,
          backgroundColor: palette.greys.lightest,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.greys.lighter,
            borderWidth: 2,
          },
        },
        multiline: {
          height: 'auto',
          padding: '10px 14px',
        },
        notchedOutline: {
          borderColor: palette.greys.lighter,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
        },
        outlined: {
          color: '#0A456D !important',
          '&:hover': {
            textDecoration: 'none',
          },
          borderColor: '#0A456D',
        },
        sizeSmall: {
          height: '20px',
        },
        outlinedPrimary: {
          backgroundColor: '#0A456D',
          color: '#fff !important',
          border: 0,
          '&$clickable': {
            '&:hover': {
              backgroundColor: '#0A456D',
              textDecoration: 'none',
              opacity: 0.8,
            },
            '.Mui-focused': {
              backgroundColor: '#0A456D',
              opacity: 0.8,
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation8: {
          boxShadow:
            '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        thumb: {
          boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.2)',
        },
        switchBase: {
          '&.Mui-checked': {
            '+ .MuiSwitch-track': {
              backgroundColor: palette.primary.light,
              opacity: 1,
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          boxShadow: '-4px 0px 10px 0px rgba(0, 0, 0, 0.15)',
        },
        paperAnchorLeft: {
          boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        focusHighlight: {
          color: 'transparent',
        },
      },
    },
  },
});

export default theme;
